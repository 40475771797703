import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { Container } from "react-bootstrap"

export default value => {
  const [option, setOption] = useState(0)
  const changeOption = e => {
    if (e === option) {
      setOption(0)
    } else {
      setOption(e)
    }
  }
  const changeNavOption = () => {
    value.value.changeNavOption(true)
  }

  const data = useStaticQuery(graphql`
    query {
      right: file(relativePath: { eq: "nav_right.png" }) {
        childImageSharp {
          fluid(maxWidth: 30, maxHeight: 30) {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      down: file(relativePath: { eq: "nav_down.png" }) {
        childImageSharp {
          fluid(maxWidth: 30, maxHeight: 30) {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  const expand = [
    <div>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: #dadbdb;
          margin: 6px auto;
        `}
      />
      <div
        css={css`
          padding-left: 25px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(51, 51, 51, 1);
          line-height: 35px;
        `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/about#intro"
          onClick={() => changeNavOption()}
        >
          <div>公司简介</div>
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/about#culture"
          onClick={() => changeNavOption()}
        >
          <div>企业文化</div>
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/about#history"
          onClick={() => changeNavOption()}
        >
          <div>发展历史</div>
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/about#contactUs"
          onClick={() => changeNavOption()}
        >
          <div>联系我们</div>
        </Link>
      </div>
    </div>,
    <div>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: #dadbdb;
          margin: 6px auto;
        `}
      />
      <div
        css={css`
          padding-left: 25px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(51, 51, 51, 1);
          line-height: 35px;
        `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/operation/"
          state={{ option: 1 }}
          onClick={() => changeNavOption()}
        >
          <div>软件系统开发</div>
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/operation/"
          state={{ option: 2 }}
          onClick={() => changeNavOption()}
        >
          <div>数据采集和分析</div>
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/operation/"
          state={{ option: 3 }}
          onClick={() => changeNavOption()}
        >
          <div>短信和银行卡身份验证</div>
        </Link>
      </div>
    </div>,
    <div>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: #dadbdb;
          margin: 6px auto;
        `}
      />
      <div
        css={css`
          padding-left: 25px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(51, 51, 51, 1);
          line-height: 35px;
        `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/recruit#positionRequires"
          onClick={() => changeNavOption()}
        >
          <div>职位招聘</div>
        </Link>
      </div>
    </div>,
    <div>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: #dadbdb;
          margin: 6px auto;
        `}
      />
      <div
        css={css`
          padding-left: 25px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(51, 51, 51, 1);
          line-height: 35px;
        `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/agreement"
          onClick={() => changeNavOption()}
        >
          <div>服务协议</div>
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/policy"
          onClick={() => changeNavOption()}
        >
          <div>隐私政策</div>
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: rgba(99, 99, 102, 1);
          `}
          to="/service-level-agreements"
          onClick={() => changeNavOption()}
        >
          <div>SLA服务</div>
        </Link>
      </div>
    </div>,
  ]

  return (
    <div
      css={css`
        width: 100%;
        margin-top:57px;
      `}
    >
      <Container>
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              width: 100%;
              margin: 0 auto;
            `}
          >
            <Link
              css={css`
                display: flex;
                cursor: pointer;
                color: hsla(0, 0%, 0%, 0.8);
                text-decoration: none;
              `}
              to="/"
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 35px;
                  margin-right: auto;
                  text-indent: 10px;
                `}
              >
                首页
              </div>
            </Link>
          </div>
        </div>
        <div
          css={css`
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                cursor: pointer;
                outline: none;
              `}
              role="button"
              onClick={() => changeOption(1)}
              onKeyDown={() => {}}
              tabIndex="0"
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 35px;
                  margin-right: auto;
                  text-indent: 10px;
                `}
              >
                关于我们
              </div>
              <Img
                fluid={
                  option === 1
                    ? data.down.childImageSharp.fluid
                    : data.right.childImageSharp.fluid
                }
                alt=""
                css={css`
                  margin-bottom: 0px;
                  width: 15px;
                  height: 19px;
                  margin-top: 6px;
                `}
              />
            </div>
            {option === 1 ? expand[0] : []}
          </div>
        </div>
        <div
          css={css`
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                cursor: pointer;
                outline: none;
              `}
              onClick={() => changeOption(2)}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 35px;
                  margin-right: auto;
                  text-indent: 10px;
                `}
              >
                核心业务
              </div>
              <Img
                fluid={
                  option === 2
                    ? data.down.childImageSharp.fluid
                    : data.right.childImageSharp.fluid
                }
                alt=""
                css={css`
                  margin-bottom: 0px;
                  width: 15px;
                  height: 19px;
                  margin-top: 6px;
                `}
              />
            </div>
            {option === 2 ? expand[1] : []}
          </div>
        </div>
        <div
          css={css`
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                cursor: pointer;
                outline: none;
              `}
              role="button"
              tabIndex="0"
              onClick={() => changeOption(3)}
              onKeyDown={() => {}}
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 35px;
                  margin-right: auto;
                  text-indent: 10px;
                `}
              >
                人才招聘
              </div>
              <Img
                fluid={
                  option === 3
                    ? data.down.childImageSharp.fluid
                    : data.right.childImageSharp.fluid
                }
                alt=""
                css={css`
                  margin-bottom: 0px;
                  width: 15px;
                  height: 19px;
                  margin-top: 6px;
                `}
              />
            </div>
            {option === 3 ? expand[2] : []}
          </div>
        </div>
        <div
          css={css`
            display: ${option === 3 ? `none` : `block`};
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />
      </Container>
    </div>
  )
}
