/* eslint no-unused-vars: 0 */
import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import style from "./styles.css"
import MobileNav from "./mobileNav"
import TweenOne from "rc-tween-one"
export default () => {
  const [navOption, setNavOption] = useState(true)
  const [size, setSize] = useState({heightSize:'',widthSize:''})
  const changeNavOption = e => {
    setNavOption(e)
    if (typeof document !== "undefined") {
      if (e === false) {
        //document.getElementById("___gatsby").className = "showNav"
        document.body.style.touchAction = "none"
        document.getElementById("mask").style.display = "block"
      } else {
        //document.getElementById("___gatsby").className = "closeNav"
        document.body.style.touchAction = "pan-y"
        document.getElementById("mask").style.display = "none"
      }
    }
  }
  if (
    navOption == false &&
    document.getElementById("mask").style.display == "block"
  ) {
    document.getElementById("mask").onclick = function () {
      changeNavOption(true)
      document.getElementById("mask").style.display = "none"
    }
  }
  useEffect(() => {
    //document.getElementById("___gatsby").className = "closeNav"
    document.body.style.touchAction = "pan-y"
    document.getElementById("mask").style.display = "none"
    setSize({heightSize:document.body.scrollHeight,widthSize:window.screen.width})
  }, [])

  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "headline.png" }) {
          childImageSharp {
            fluid(maxWidth: 158, maxHeight: 49) {
              base64
              aspectRatio
              sizes
              src
              srcSet
            }
          }
        }
        nav_on: file(relativePath: { eq: "nav_on.png" }) {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              sizes
              src
              srcSet
            }
          }
        }
        nav_off: file(relativePath: { eq: "nav_off.png" }) {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              sizes
              src
              srcSet
            }
          }
        }
      }
    `
  )
  return (
    <div className="nav">
      <Container
        css={css`
          height: 76px;
        `}
      >
        <Row
          css={css`
            position: relative;
          `}
        >
          <div
            css={css`
              display: inline-block;
              position: absolute;
              left: 0%;
              top: 16%;
              opacity: 0.8;
              outline: none;
              z-index: 10;
            `}
            className="navImg"
            onClick={() => changeNavOption(!navOption)}
            onKeyDown={() => {}}
            tabIndex="0"
          >
            <Img
              fluid={
                navOption === false
                  ? data.nav_off.childImageSharp.fluid
                  : data.nav_on.childImageSharp.fluid
              }
              alt=""
            />
          </div>
          <Col xl="14" lg="14" md="24" sm="24" xs="24">
            <Link to="/">
              <div
                css={css`
                  display: inline-block;
                  padding: 4px 0px 12px 2px;
                  width: 100%;
                `}
              >
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt=""
                  className="titleImg"
                />
              </div>
            </Link>
          </Col>
          <Col className="bar" xl="10" lg="10" md="0" sm="0" xs="0">
            <Row
              css={css`
                font-size: 16px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                display: flex;
                justify-content: space-around;
              `}
            >
              <Col xl="4" lg="4" md="4" sm="4">
                <div
                  css={css`
                    position: relative;
                    text-align: center;
                    :hover {
                      div {
                        display: block;
                      }
                    }
                  `}
                >
                  <Link
                    to="/"
                    css={css`
                      color: rgba(255, 255, 255, 1);
                      line-height: 76px;
                      text-decoration: none;
                    `}
                  >
                    首页
                  </Link>
                  <div
                    css={css`
                      width: 4px;
                      height: 4px;
                      background: rgba(255, 255, 255, 1);
                      position: absolute;
                      border-radius: 4px;
                      display: none;
                      left: 48%;
                      top: 60px;
                    `}
                  />
                </div>
              </Col>
              <Col xl="5" lg="5" md="5" sm="5">
                <div
                  css={css`
                    position: relative;
                    text-align: center;
                    text-decoration: none;
                    :hover {
                      div {
                        display: block;
                      }
                    }
                  `}
                >
                  <Link
                    to="/about/"
                    css={css`
                      color: rgba(255, 255, 255, 1);
                      line-height: 76px;
                      text-decoration: none;
                    `}
                  >
                    关于我们
                  </Link>
                  <div
                    css={css`
                      width: 4px;
                      height: 4px;
                      background: rgba(255, 255, 255, 1);
                      position: absolute;
                      border-radius: 4px;
                      display: none;
                      left: 48%;
                      top: 60px;
                    `}
                  />
                </div>
              </Col>
              <Col xl="5" lg="5" md="5" sm="5">
                <div
                  css={css`
                    position: relative;
                    text-align: center;
                    :hover {
                      div {
                        display: block;
                      }
                    }
                  `}
                >
                  <Link
                    to="/operation/"
                    state={{ option: 1 }}
                    css={css`
                      color: rgba(255, 255, 255, 1);
                      line-height: 76px;
                      text-decoration: none;
                    `}
                  >
                    核心业务
                  </Link>
                  <div
                    css={css`
                      background: rgba(255, 255, 255, 1);
                      border-radius: 11px;
                      position: absolute;
                      top: 85px;
                      font-size: 16px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: rgba(106, 106, 106, 1);
                      display: none;
                      text-align: left;
                      z-index: 100;
                    `}
                    className="bubble-1-c"
                  >
                    <div
                      css={css`
                        line-height: 40px;
                      `}
                      className="nav-c"
                    >
                      <Link
                        to="/operation/"
                        state={{ option: 1 }}
                        css={css`
                          text-decoration: none;
                          color: rgba(106, 106, 106, 1);
                          :hover {
                            color: #f77920;
                          }
                        `}
                      >
                        <div>软件系统开发</div>
                      </Link>
                      <Link
                        to="/operation/"
                        state={{ option: 2 }}
                        css={css`
                          text-decoration: none;
                          color: rgba(106, 106, 106, 1);
                          :hover {
                            color: #f77920;
                          }
                        `}
                      >
                        <div>数据采集和分析</div>
                      </Link>
                      <Link
                        to="/operation/"
                        state={{ option: 3 }}
                        css={css`
                          text-decoration: none;
                          color: rgba(106, 106, 106, 1);
                          :hover {
                            color: #f77920;
                          }
                        `}
                      >
                        <div>短信和银行卡身份验证</div>
                      </Link>
                    </div>
                  </div>
                  <div
                    css={css`
                      border: 30px solid;
                      border-color: transparent transparent white;
                      position: absolute;
                      top: 40px;
                      display: none;
                    `}
                    className="bubble-1-h"
                  />
                  <div
                    css={css`
                      width: 4px;
                      height: 4px;
                      background: rgba(255, 255, 255, 1);
                      position: absolute;
                      border-radius: 4px;
                      display: none;
                      left: 48%;
                      top: 60px;
                    `}
                  />
                </div>
              </Col>
              <Col xl="5" lg="5" md="5" sm="5">
                <div
                  css={css`
                    position: relative;
                    text-align: center;
                    :hover {
                      div {
                        display: block;
                      }
                    }
                  `}
                >
                  <Link
                    to="/recruit/"
                    css={css`
                      color: rgba(255, 255, 255, 1);
                      line-height: 76px;
                      text-decoration: none;
                    `}
                  >
                    人才招聘
                  </Link>
                  <div
                    css={css`
                      width: 4px;
                      height: 4px;
                      background: rgba(255, 255, 255, 1);
                      position: absolute;
                      border-radius: 4px;
                      display: none;
                      left: 48%;
                      top: 60px;
                    `}
                  />
                </div>
              </Col>
              <Col xl="5" lg="5" md="5" sm="5">
                <div
                  css={css`
                    position: relative;
                    text-align: center;
                    :hover {
                      div {
                        display: block;
                      }
                    }
                  `}
                >
                  <Link
                    to="/agreement"
                    css={css`
                      display: block;
                      text-decoration: none;
                      color: rgba(255, 255, 255, 1);
                      line-height: 76px;
                      text-align: center;
                    `}
                  >
                    政策协议
                  </Link>
                  <div
                    css={css`
                      background: rgba(255, 255, 255, 1);
                      border-radius: 11px;
                      position: absolute;
                      top: 85px;
                      font-size: 16px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: rgba(106, 106, 106, 1);
                      display: none;
                      text-align: left;
                      z-index: 100;
                    `}
                    className="bubble-2-c"
                  >
                    <div
                      css={css`
                        line-height: 40px;
                      `}
                      className="nav-c"
                    >
                      <Link
                        to="/agreement"
                        css={css`
                          text-decoration: none;
                          color: rgba(106, 106, 106, 1);
                          :hover {
                            color: #f77920;
                          }
                        `}
                      >
                        <div>服务协议</div>
                      </Link>
                      <Link
                        to="/policy/"
                        css={css`
                          text-decoration: none;
                          color: rgba(106, 106, 106, 1);
                          :hover {
                            color: #f77920;
                          }
                        `}
                      >
                        <div>隐私政策</div>
                      </Link>
                      <Link
                        to="/service-level-agreements"
                        css={css`
                          text-decoration: none;
                          color: rgba(106, 106, 106, 1);
                          :hover {
                            color: #f77920;
                          }
                        `}
                      >
                        <div>SLA服务</div>
                      </Link>
                    </div>
                  </div>
                  <div
                    css={css`
                      border: 30px solid;
                      border-color: transparent transparent white;
                      position: absolute;
                      top: 40px;
                      display: none;
                    `}
                    className="bubble-2-h"
                  />
                  <div
                    css={css`
                      width: 4px;
                      height: 4px;
                      background: rgba(255, 255, 255, 1);
                      position: absolute;
                      border-radius: 4px;
                      display: none;
                      left: 48%;
                      top: 60px;
                    `}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <TweenOne
        animation={
          navOption === true
            ? {
                duration: 500,
                left: "-300px",
              }
            : {
                duration: 500,
                left: "0",
              }
        }
        paused={false}
        moment={null}
        css={css`
          position: absolute;
          width: 300px;
          left: -300px;
          top: 0px;
          height: ${size.heightSize}px;
          z-index: 101;
          background-color: white;
        `}
      >
        <MobileNav value={{ changeNavOption }} />
      </TweenOne>

      {/* 移动端罩层 */}
      <div
        css={css`
          position: absolute;
          left: 0px;
          top: 0px;
          width: ${size.widthSize}px;
          height: ${size.heightSize}px;
          background: #000;
          opacity: 0.3;
          z-index: 100;
        `}
        id="mask"
      />
    </div>
  )
}
